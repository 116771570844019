import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style.css";
import ScrollToTop from "../../ScrollToTop";
import { createAPIEndpoint, ENDPOINTS } from  "../../api";
//import "./style.css";

const sliders = [
  {
    stext: "They have wide variety of collection with premium quality. Got a pendant and earrings for reasonable prices. It’s value for money☺",
    sTitle: "Poornima Nukala",
    subTitle: "",
  },
  {
    stext: "They have wide variety of collection with premium quality. Got a pendant and earrings for reasonable prices. It’s value for money☺",
    sTitle: "Poornima Nukala",
    subTitle: "",
  },
];
const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
};

const Testmonial = ({ className }) => {
  const [data, setData] = useState([]);



  useEffect(() => {
    setData(sliders);
    createAPIEndpoint(ENDPOINTS.getTestimonials)
    .post()
    .then(res =>
       {
        // console.log(res.data);
      setData(JSON.parse(res.data));
    
    })
    .catch(err => {   
  
      // console.log(err);
  });
  }, []);
  return (
    <div className={className} id="testimonials">
      <section className="section-testimonial">
        <div className="container">
          <div className="testimonialContent">
            <h5 className="h-2 text-center">What People Say</h5>
            <Slider className="testmonialSlider slider padSlider"  {...settings}>
              
              {data.map((slider, i) => (
                <div key={i} className="slideItem d-flex">
                  <p className="p-1 text-center">{slider.stext}</p>
                  <div className="thumbWrap">
                    <div className="imgContent">
                      <div className="d-flex">
                        <p className="t-c-name text-center">{slider.sTitle}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

        </div>
      </section>
      <ScrollToTop/>
    
    </div>
  );
};
export default Testmonial;
