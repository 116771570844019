import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import HeroSlider from "../HeroSlider/index";
import "./style.css"
import "../../style.css"
const heroSliders = [
  {
    images: "1",
    title: "of experience & a team of",
    subTitle: "super-talented and specialized lawyers.",
    text: "We have accumulated years",
    button: "Contact us now",
    link: "Contact",
  },
  {
    images: "2",
    title: "right to Justic",
    subTitle: "above all.",
    text: "We values our client's",
    button: "Contact us now",
    link: "Contact",
  },
  {
    images: "3",
    title: "right to Justic",
    subTitle: "above all.",
    text: "We values our client's",
    button: "Contact us now",
    link: "Contact",
  },
  {
    images: "4",
    title: "right to Justic",
    subTitle: "above all.",
    text: "We values our client's",
    button: "Contact us now",
    link: "Contact",
  },
];
const initialSuggestions = [...Array(20).keys()].map((index) => ({
  name: `Suggestion ${index + 1}`
}));

const ItemModal = ({imageName,maxIndex, item, onItemChange, ...rest }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  var arrayImageBig = [];   
  var arrayImageSmall = [];   
  for (let i = 1; i <= maxIndex; i++) {
    
   var sImg="/img/products/Gallery/All/Small/"+imageName+"_"+i+".jpg"
   var bImg="/img/products/Gallery/All/Big/"+imageName+"_"+i+".jpg"
   arrayImageBig.push( bImg  );   
   arrayImageSmall.push(sImg)
}
  useEffect(() => {
    setSuggestions(initialSuggestions);
  }, []);

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => (
    <h2 style={{ margin: "20px 10px" }}>{suggestion.name}</h2>
  );

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(initialSuggestions);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Type a programming language",
    value,
    onChange: onChange
  };

  return (
    <Modal {...rest} fullscreen style={{backgroundColor:"black"}}>
      <Modal.Header closeButton style={{backgroundColor:"gray",opacity:".9", border:"none",color:"while !important"}}>
        <Modal.Title id="contained-modal-title-lg"  style={{backgroundColor:"transparent",border:"none",color:"Black !important"}}> 
         Yuvaish Jewels  
         </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:"black"}}>
        {/* {[...Array(20).keys()].map((index) => (
          <h2 key={`heading-${index}`}>Heading {index + 1}</h2>
        ))} */}
 
        <HeroSlider imageName={imageName} maxIndex={maxIndex} arrayimageSmall={arrayImageSmall} arrayimageBig={arrayImageBig} sliders={heroSliders} className="heroSliderArea" />
      </Modal.Body>
      <Modal.Footer style={{backgroundColor:"black",border:"none"}} />
    </Modal>
  );
};

export default ItemModal;
