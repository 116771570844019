import "../../style.css";
import React from "react";
import Faq from "react-faq-component";

function Faqs() {
  // const tableData = [
 
  //   {
  //     trTitle: "Durability",
  //     trDesc:"Gemstones are measured on the Mohs Scale of Hardness, which assesses a gem’s ability to withstand surface scratching.",
  //     MoissaniteHeader:"Moissanite",
  //     MoissaniteContent:"On the Mohs scale, Moissanite gemstones are a 9.25 on the Mohs Scale of Hardness, so they are suitable for daily wear.",
  //     DiamondHeader:"Diamond",
  //     DiamondContent:"Diamonds are the hardest known mineral and receive a 10 on the Mohs Scale of Hardness. Because of this exceptional score, diamonds are very durable and ideal for daily wear. this contributes to their popularity as engagement rings’ center gemstones."
  //   },

  //   {
  //     trTitle: "Brilliance",
  //     trDesc:"Brilliance refers to the appearance of light reflected from the interior of the gem.",
  //     MoissaniteHeader:"",
  //     MoissaniteContent:'Moissanites exhibit a different kind of brilliance than diamonds do, as their faceting pattern is different. The fiery, rainbow flashes emitted by moissanites are beloved bysome, but others feel that moissanite’s heightened brilliance can create a “discoball” effect, especially in sunlight. The bigger the moissanite, the more likely it isthat the difference will be noticeable. Moissanite has a refractive index from 2.65 –2.69, which is higher than a natural diamond.',
  //     DiamondHeader:"",
  //     DiamondContent:"A different type of brilliance is what makes it possible to distinguish a moissanite from a diamond. Diamonds reflect light in three different ways. The white light reflected back is referred to as brilliance, while the rainbow of colors refracted through the diamond is referred to as dispersion. The surface sparkle of a diamond, known as scintillation, is a third type of diamond light return. The combination of these three gives diamonds their famous sparkle."
  //   },
  //   {
  //     trTitle: "Color",
  //     trDesc:"Color is the natural color or lack of color visible within a gemstone.",
  //     MoissaniteHeader:"",
  //     MoissaniteContent:"While moissanites are labeled as “colorless”, the gems can still project a yellow or grayish hue in certain lights. Here again, the larger the moissanite, the more noticeable the color.",
  //     DiamondHeader:"",
  //     DiamondContent:"A colorless diamond, whether natural or lab created, has a natural body color that contains no traces of yellow, brown, or grey, resulting in a dazzling, bright white appearance."
  //   },
  //   {
  //     trTitle: "Price",
  //     trDesc:"",
  //     MoissaniteHeader:"",
  //     MoissaniteContent:"For the same size from the top view, moissanites are dramatically lower in pricing than diamonds of that size. Moissanite gems typically vary in price only based on size and whether the stone is Premium or Super Premium.",
  //     DiamondHeader:"",
  //     DiamondContent:"Natural diamonds and lab created diamond prices vary based on shape, carat, cut, color,and clarity. Lab created diamonds are more affordable than natural diamonds."
  //   },
  //   {
  //   trTitle: "Sourcing",
  //   trDesc:"",
  //   MoissaniteHeader:"",
  //   MoissaniteContent:"Because moissanites are lab created, they are an appealing option for those seeking an eco-conscious gemstone as they require no mining.",
  //   DiamondHeader:"",
  //   DiamondContent:"We go above and beyond the current industry standards to offer both Beyond Conflict Free ™ Diamonds that have been selected for their ethical and environmentally responsible origins, as well as lab created diamonds that are ethically grown with minimal environmental impact."
  // },
  // ]
  const data = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "What is Moissanite?",
        content: `Moissanite is one of the most brilliant gemstones in the world. It is naturally occurring mineral, silicon carbide, that is found in limited quantities in the earth. While moissanite exists in nature, it is so incredibly rare that it must be created in a lab to produce pieces large enough to cut into gemstones . It shares many similar properties with diamond including a very diamond like appearance. Moissanite can pass the diamond test and are one of the hardest and toughest known elements on earth, making it extremely resistant to scratching, chipping, and breaking. 
        `,
      },
      {
        title: "Do you get certificate with Moissanite?",
        content: `Our moissanites are similarly certified through GRA (Gemological Research Association), which each moissanite graded for its 4C's similar to diamonds, so you can be confident that your stone is certified one of a kind. Moissanites certificates are delivered with your order.`,
      },
      {
        title: "Do Moissanite hold value?",
        content:
          "Yes, moissanite engagement rings have a healthy value in the ring resale marketplace. ",
      },
      {
        title: "Are Moissanite worth buying?",
        content: `Yes. Moissanites are strong, long-lasting gemstones. A moissanite should last for your entire life without losing its appearance or becoming damaged. If you choose a setting that's made from a high-quality metal such as silver, gold and white gold a moissanite ring will last for a very long time.
        `,
      },
      {
        title: "Moissanite vs Diamond (Durability)?",
        content: (
          <p>
            Durability:
            <br />
            Gemstones are measured on the Mohs Scale of Hardness, which assesses
            a gem’s ability to withstand surface scratching.
            <br />
            <br />
            Moissanite:
            <br />
            On the Mohs scale, Moissanite gemstones are a 9.25 on the Mohs Scale
            of Hardness, so they are suitable for daily wear.
            <br />
            <br />
            Diamond:
            <br />
            Diamonds are the hardest known mineral and receive a 10 on the Mohs
            Scale of Hardness. Because of this exceptional score, diamonds are
            very durable and ideal for daily wear. this contributes to their
            popularity as engagement rings’ center gemstones.
          </p>
        ),
      },
      {
        title: "Moissanite vs Diamond (Brilliance)?",
        content: (
          <p>
            Brilliance:
            <br />
            Brilliance refers to the appearance of light reflected from the
            interior of the gem.
            <br />
            <br />
            Moissanite:
            <br />
            Moissanites exhibit a different kind of brilliance than diamonds do,
            as their faceting pattern is different. The fiery, rainbow flashes
            emitted by moissanites are beloved bysome, but others feel that
            moissanite’s heightened brilliance can create a “discoball” effect,
            especially in sunlight. The bigger the moissanite, the more likely
            it isthat the difference will be noticeable. Moissanite has a
            refractive index from 2.65 –2.69, which is higher than a natural
            diamond.
            <br />
            <br />
            Diamond:
            <br />A different type of brilliance is what makes it possible to
            distinguish a moissanite from a diamond. Diamonds reflect light in
            three different ways. The white light reflected back is referred to
            as brilliance, while the rainbow of colors refracted through the
            diamond is referred to as dispersion. The surface sparkle of a
            diamond, known as scintillation, is a third type of diamond light
            return. The combination of these three gives diamonds their famous
            sparkle.
          </p>
        ),
      },
      {
        title: "Moissanite vs Diamond (Color)?",
        content: (
          <p>
            Color:
            <br />
            Color is the natural color or lack of color visible within a
            gemstone.
            <br />
            <br />
            Moissanite:
            <br />
            While moissanites are labeled as “colorless”, the gems can still
            project a yellow or grayish hue in certain lights. Here again, the
            larger the moissanite, the more noticeable the color.
            <br />
            <br />
            Diamond:
            <br />A colorless diamond, whether natural or lab created, has a
            natural body color that contains no traces of yellow, brown, or
            grey, resulting in a dazzling, bright white appearance.
          </p>
        ),
      },
      {
        title: "Moissanite vs Diamond (Price)?",
        content: (
          <p>
            Moissanite:
            <br />
            For the same size from the top view, moissanites are dramatically
            lower in pricing than diamonds of that size. Moissanite gems
            typically vary in price only based on size and whether the stone is
            Premium or Super Premium.
            <br />
            <br />
            Diamond:
            <br />
            Natural diamonds and lab created diamond prices vary based on shape,
            carat, cut, color,and clarity. Lab created diamonds are more
            affordable than natural diamonds.
          </p>
        ),
      },
      {
        title: "Moissanite vs Diamond (Sourcing)?",
        content: (
          <p>
            Sourcing:
            <br />
            Because moissanites are lab created, they are an appealing option
            for those seeking an eco-conscious gemstone as they require no
            mining.
            <br />
            <br />
            Diamond:
            <br />
            We go above and beyond the current industry standards to offer both
            Beyond Conflict Free ™ Diamonds that have been selected for their
            ethical and environmentally responsible origins, as well as lab
            created diamonds that are ethically grown with minimal environmental
            impact.
          </p>
        ),
      },
    ],
  };

  const styles = {
    bgColor: "none",
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "white",
    tittleBottomPadding: "2.4rem",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };
  return (
    <section id={ "Faqs"+  Math.floor(Math.random() * (100))} className="section-faq padit">
      <div className="container">
        <div>
          <Faq data={data} styles={styles} config={config} />
          {/* <h2 className="hmd-2">Moissanite vs. Diamonds</h2>
           
           {tableData.map((tbl, i) => (
              
                
              <table>
                     <tr className="pmd-1">
                       <td className="pmd-1td" colSpan={"2"}  >
                       <div  className=" centerDiv">
                   <h3>{tbl.trTitle}</h3>
                   {tbl.trDesc}
                   </div>
                       </td>
                       </tr>
                      <tr className="pmd-1">
                     <td  className="pmd-1td" ><h3>{"Moissanite"}</h3> <br/><br/>
                     {tbl.MoissaniteContent}
                     </td>
                     <td  className="pmd-1td" ><h3>{"Diamond"}</h3> <br/><br/>
                     {tbl.DiamondContent}
                     </td>
                    </tr>
                    </table>

           ))} */}
        </div>
      </div>
    
    </section>
  );
}

export default Faqs;
