 
import '../style.css';
import Menu from '../_component/menu';
 
import About from '../_page/about';
 
import ContactUs from '../_page/contactUs';
import Footer from '../_component/footer';
 
 
function Page() {
  return (
    <>
    <div   className="main-body-bg"></div>
    <div className="App">
 
    <Menu activePage="about"/>
 <main>
  
  <About id="a1"/>
 
  <ContactUs id="c1"/>
  <Footer  />

  </main>
    </div>

    </>
  );
}

export default Page;
