export function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
 export function handleChange(event) {
    console.log(event.target.value);
  }
// const heroSliders = [
//     {
//       images: "slideWrapperOne",
//       title: "We Fight For Your Justice",
//       subTitle: "As Like A Friend.",
//       text: "The Most Talented Law Frim",
//       button: "Contact us now",
//     },
//     {
//       images: "slideWrapperTwo",
//       title: "Of experience & a team of",
//       subTitle: "Super-talented and specialized lawyers.",
//       text: "We have accumulated years",
//       button: "Contact us now",
//     },
//   ];
// const services = [
 
//     {
//       icon: 'flaticon-parents',
//       title: 'Family Law',
//       content: 'Yuvaish Jewels, can help you in multiple ways. From court cases & arbitration to mediation, we can help you get the best outcome...',
//       link:'AboutPage'
//   },
//   {
//       icon: 'flaticon-wounded',
//       title: 'Civil Litigation',
//       content: 'At Yuvaish Jewels, we offer professional & tailored civil litigation services to our clients according to their needs and goals... ',
//       link:'AboutPage'
//   },
//   {
//       icon: 'flaticon-employee',
//       title: 'Business Law',
//       content: 'it\'s better to have a team of lawyers who can manage all your legal Business issues effectively. At Yuvaish Jewels, we offer...',
//       link:'AboutPage'
//   },
//   {
//       icon: 'flaticon-thief',
//       title: 'Criminal Law',
//       content: 'We are a team of professional & specialized criminal lawyers with years of experience. When it comes to legal advice, we offer precise...',
//       link:'AboutPage'
//   },
//   {
//       icon: 'flaticon-university-graduate-hat',
//       title: 'Immigration',
//       content: 'Planning to migrate or settle in Canada, one of the best countries in the world? Excellent decision! Canada offers immense...',
//       link:'AboutPage'
//   },
//   {
//       icon: 'flaticon-house',
//       title: 'Real Estate Law',
//       content: 'Making a decision to buy any property is a big one, financially & emotionally. So you need support from a team of experienced & reliable...',
//       link:'AboutPage'
//   },
//   {
    
//       icon: 'flaticon-house',
//       title: 'Wills & Estate',
//       content: 'At Yuvaish Jewels, we provide Will & Estate services to help our esteemed client in Ontario.  Whether you want to prepare your own Will...',
//       link:'AboutPage'
//   },
//   ];