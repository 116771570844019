 
import '../../../style.css';
import Menu from '../../../_component/menu';
 
import ProductGallery from '../../../_page/Gallery/Sliver/Pendant';
 
import ContactUs from '../../../_page/contactUs';
import Footer from '../../../_component/footer';
 
function Page() {
  return (
    <>
    <div className="main-body-bg"></div>
    <div className="App">
 <Menu/>
 <main>
  
  <ProductGallery/>
 
  <ContactUs/>
  <Footer/>
 
  </main>
    </div>
    </>
  );
}

export default Page;
