 
import '../style.css';
import Menu from '../_component/menu';
import Hero from '../_component/hero';
import About from '../_page/about';
import Product from '../_page/product';
import Testimonials from '../_page/testimonials';
import Faqs from '../_page/faqs';
import ContactUs from '../_page/contactUs';
import Footer from '../_component/footer';
 
function Page() {
  return (
    <>
    <div className="main-body-bg"></div>
    <div className="App">
 <Menu/>
 <main>
  
 
 
  <ContactUs/>
  <Footer/>
 
  </main>
    </div>
    </>
  );
}

export default Page;
