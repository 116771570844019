import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from './home';
import ProductGallery from './productGallery';
import About from "./about"
import Product from './product';
import Testmonial from './testimonials';
import ContactUs from './contactUs';

import Faqs from './faqs';

const isLocal = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
 
if  (!isLocal && window.protocol === 'http:') {
  window.location.replace(`https:${window.href.substring(window.protocol.length)}`);
}
else{
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    
    <React.StrictMode>
    
      <App />
      
    </React.StrictMode>
  );
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
  // const app = (
//     <Provider store={store}>
//         <App />
//     </Provider>
// )

// ReactDOM.createRoot(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

 }

