 
 
import logo from '../../img/logo/YV-WEB-LOGO.png'
import '../../style.css'
import {useState} from 'react';
  import { Link } from "react-router-dom";
  import { useRef } from 'react';
import ScrollToTop from '../../ScrollToTop';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar  from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

import {Container, Nav,Navbar,NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
function Menu(props) {
  const activePage = props.activePage;
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}
const showDropdowns = (e)=>{
  setShows(!shows);
}
const hideDropdowns = e => {
  setShows(false);
}

  const handleClick = (e) => {
    
    const allWithClass = Array.from(
      document.getElementsByClassName('m1')
    );
    console.log(allWithClass);

    allWithClass.forEach(element => {
      element.className = 'nav-link nav-link-hower m1';
      
    });
    e.className="nav-link nav-link-active m1";
    
 
  };
 
  const handleClickScroll = () => {
    const element = document.getElementById('home');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <header id="home"  className="header">
      
    <Navbar expand="lg" bg="dark"  sticky="top" data-bs-theme="dark"  >
      <Container>
        <Link to="/"><img className="logo" src={logo} alt="Yuvaish-jewels-logo" /></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          </Nav>
          <Nav className="me-auto">
            <Link to="/" 
             className= { activePage === '/' ?  'nav-link nav-link-active m1' : 
             "nav-link nav-link-hover m1"
             }   
           >Home</Link>
            <Link to="/about" 
              className= { activePage === 'about' ?  'nav-link nav-link-active m1' : 
              "nav-link nav-link-hover m1"
             }  
            >About Us</Link>
            <Link to="/product"  
            className= { activePage === 'product' ?  'nav-link nav-link-active m1' : 
              "nav-link nav-link-hover m1"}    onClick={handleClickScroll}
              >Products</Link>
          
          {/* NavBAr for New Silver And Gold Menu Start From Here */}
                        {/* <Nav className="me-auto">
                        <NavDropdown   className= { activePage === 'Products' ?  'nav-link nav-link-active dropDown m1' : 
                        "nav-link nav-link-hover m1 dropDown"} title="Products" id="ddProduct"
                        // show={show}
                        // onMouseEnter={showDropdown} 
                        // onMouseLeave={hideDropdown}
                        >
                        <NavDropdown className= { activePage === 'f' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}  title="Silver and Moissanite" id="ddMoons"
                        // show={shows}
                        // onMouseEnter={showDropdowns} 
                        // onMouseLeave={hideDropdowns}
                        >
                        <Link  className= { activePage === 'sRing' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}  to="/sRing">
                        Rings 
                        </Link>
                        
                        <NavDropdown.Divider />
                        <Link    className= { activePage === 'sEarRing' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}   to="/sEarRing"
                      
                        
                        >
                        Ear Rings
                        </Link>
                        <NavDropdown.Divider />
                        <Link className= { activePage === 'sPendant' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}  to="/sPendant">
                        Pendant
                        </Link>
                        
                      </NavDropdown>
                      <NavDropdown className= { activePage === 'x' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}  title="Gold and Diamond" id="ddMoons"
                        // show={shows}
                        // onMouseEnter={showDropdowns} 
                        // onMouseLeave={hideDropdowns}
                        >
                        <Link  className= { activePage === 'gRing' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}  to="/gRing">
                        Rings 
                        </Link>
                        
                        <NavDropdown.Divider />
                        <Link    className= { activePage === 'gEarRing' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}   to="/gEarRing"
                      
                        
                        >
                        Ear Rings
                        </Link>
                        <NavDropdown.Divider />
                        <Link className= { activePage === 'gPendant' ?  'nav-link nav-link-active m1' : 
                        "nav-link nav-link-hover m1"}  to="/gPendant">
                        Pendant
                        </Link>
                        
                      </NavDropdown>
                      </NavDropdown>
                      </Nav> */}
            {/* Nav Bar for silver and Gold Ends Here */}
            <Link to="/testimonials" className= { activePage === 'testimonials' ?  'nav-link nav-link-active m1' : 
              "nav-link nav-link-hover m1"}      >Testimonials</Link>
            <Link to="/Faqs" className= { activePage === 'faqs' ?  'nav-link nav-link-active m1' : 
              "nav-link nav-link-hover m1"}    >FAQ's</Link>
            <Link to="/ContactUs" className={ 'nav-link btn-secondary'}   >Contact Us</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      
<ScrollToTop/>
   </header>

  );
}

export default Menu;
