import "../../../../../src/style.css";
import P1 from '../../../../img/products/Gallery/All/e10_1.jpg';
import P2 from '../../../../img/products/Gallery/All/e11_1.jpg';
import P3 from '../../../../img/products/Gallery/All/e12_1.jpg';
import P4 from '../../../../img/products/Gallery/All/e14_1.jpg';
import P5 from '../../../../img/products/Gallery/All/e1_1.jpg';
import P6 from '../../../../img/products/Gallery/All/e2_1.jpg';
import P7 from '../../../../img/products/Gallery/All/e3_1.jpg';
import P8 from '../../../../img/products/Gallery/All/e4_1.jpg';
import P9 from '../../../../img/products/Gallery/All/e5_1.jpg';
import P10 from '../../../../img/products/Gallery/All/e6_1.jpg';
import P11 from '../../../../img/products/Gallery/All/e7_1.jpg';
import P12 from '../../../../img/products/Gallery/All/e8_1.jpg';
import P13 from '../../../../img/products/Gallery/All/e9_1.jpg';
import P14 from '../../../../img/products/Gallery/All/p1_1.jpg';
import P15 from '../../../../img/products/Gallery/All/p2_1.jpg';
import P16 from '../../../../img/products/Gallery/All/p3_1.jpg';
import P17 from '../../../../img/products/Gallery/All/p4_1.jpg';
import P18 from '../../../../img/products/Gallery/All/p5_1.jpg';
import P19 from '../../../../img/products/Gallery/All/p6_1.jpg';
import P20 from '../../../../img/products/Gallery/All/p7_1.jpg';
import P21 from '../../../../img/products/Gallery/All/r10_1.jpg';
import P22 from '../../../../img/products/Gallery/All/r11_1.jpg';
import P23 from '../../../../img/products/Gallery/All/r12_1.jpg';
import P24 from '../../../../img/products/Gallery/All/r13_1.jpg';
import P25 from '../../../../img/products/Gallery/All/r14_1.jpg';
import P26 from '../../../../img/products/Gallery/All/r15_1.jpg';
import P27 from '../../../../img/products/Gallery/All/r16_1.jpg';
import P28 from '../../../../img/products/Gallery/All/r17_1.jpg';
import P29 from '../../../../img/products/Gallery/All/r18_1.jpg';
import P30 from '../../../../img/products/Gallery/All/r1_1.jpg';
import P31 from '../../../../img/products/Gallery/All/r2_1.jpg';
import P32 from '../../../../img/products/Gallery/All/r3_1.jpg';
import P33 from '../../../../img/products/Gallery/All/r4_1.jpg';
import P34 from '../../../../img/products/Gallery/All/r5_1.jpg';
import P35 from '../../../../img/products/Gallery/All/r6_1.jpg';
import P36 from '../../../../img/products/Gallery/All/r7_1.jpg';
import P37 from '../../../../img/products/Gallery/All/r8_1.jpg';
import P38 from '../../../../img/products/Gallery/All/r9_1.jpg';




 import Ring from "../Ring/index"
 import ItemModal from "../../../ItemModal";
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import 'react-inner-image-zoom/lib/img/styles.min.css';
// import InnerImageZoom from 'react-inner-image-zoom';
function ProductGallery( ) {
  const [show, setShow] = useState(false);
  const [imageName, setImageName] = useState("");
  const [maxIndex, setMaxIndex] = useState(1);
  function handleShow(sImageName,mIndex) {
    // setFullscreen(breakpoint);
    setImageName(sImageName);
    setMaxIndex(mIndex);
    setShow(true);
    
  }
  
 
  return (


 <section id="product" className="section-product padit">
    <ItemModal
            imageName={imageName}
            maxIndex={maxIndex}
            show={show}
            onHide={() => setShow(false)}
          />
     <div className="container">
       <h2 className="h-2 text-center p-small">Our Products Gallery</h2>
       <div className="sm-container">
         {/* icon for instagram */}
         <a href="https://instagram.com/yuvaishjewels?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target={"_blank"}  className="sm-link">
           <svg
             className="sm-icon"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 333333 333333"
             shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
             imageRendering="optimizeQuality"
             fillRule="evenodd"
             clipRule="evenodd"
           >
             <path d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm-40642 71361h81288c30526 0 55489 24654 55489 54772v81069c0 30125-24963 54771-55488 54771l-81289-1c-30526 0-55492-24646-55492-54771v-81069c0-30117 24966-54771 55492-54771zm40125 43843c29663 0 53734 24072 53734 53735 0 29667-24071 53735-53734 53735-29672 0-53739-24068-53739-53735 0-29663 24068-53735 53739-53735zm0 18150c19643 0 35586 15939 35586 35585 0 19647-15943 35589-35586 35589-19650 0-35590-15943-35590-35589s15940-35585 35590-35585zm51986-25598c4819 0 8726 3907 8726 8721 0 4819-3907 8726-8726 8726-4815 0-8721-3907-8721-8726 0-4815 3907-8721 8721-8721zm-85468-20825h68009c25537 0 46422 20782 46422 46178v68350c0 25395-20885 46174-46422 46174l-68009 1c-25537 0-46426-20778-46426-46174v-68352c0-25395 20889-46177 46426-46177z" />
           </svg>
         </a>

         {/* icon for tiktok */}
         <a href="https://www.tiktok.com/@yuvaish.jewels?_t=8eSreZsGbxe&_r=1" target={"_blank"} className="sm-link">
           <svg
             className="sm-icon"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 3333 3333"
             shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
             imageRendering="optimizeQuality"
             fillRule="evenodd"
             clipRule="evenodd"
           >
             <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm361 744c31 262 177 418 430 434v294c-147 14-276-34-426-124v550c0 700-763 918-1069 417-197-322-76-889 556-911v311c-48 8-99 20-146 36-141 47-220 137-198 294 43 301 595 390 549-198V745h305z" />
           </svg>
         </a>

         {/* icon for facebook */}
         <a href="https://www.facebook.com/profile.php?id=100095247736793&mibextid=ZbWKwL"  target={"_blank"}  className="sm-link">
           <svg
             className="sm-icon"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 3333 3333"
             shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
             imageRendering="optimizeQuality"
             fillRule="evenodd"
             clipRule="evenodd"
           >
             <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm186 1117h311V744h-311c-240 0-435 195-435 435v186h-249v373h249v994h373v-994h311l62-373h-373v-186c0-34 28-62 62-62z" />
           </svg>
         </a>
       </div>
       {/* <p className="p-1 text-center f-small">
         We deliver premium on trend jewellery for the minimum price to have
         more impact on your style.
       </p> */}
       <div>
         <h3 className="h-3 text-center">Moissanite & Silver</h3>
         <div className="product-rings">
         
         <div className='p-z  centerDiv' onClick={() => handleShow('e10',3)} > <img zoomScale=".5"  className='product-img'  src={P1} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e11',2)} > <img zoomScale=".5"  className='product-img'  src={P2} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e12',1)} > <img zoomScale=".5"  className='product-img'  src={P3} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e14',3)} > <img zoomScale=".5"  className='product-img'  src={P4} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e1',3)} > <img zoomScale=".5"  className='product-img'  src={P5} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e2',3)} > <img zoomScale=".5"  className='product-img'  src={P6} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e3',2)} > <img zoomScale=".5"  className='product-img'  src={P7} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e4',2)} > <img zoomScale=".5"  className='product-img'  src={P8} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e5',4)} > <img zoomScale=".5"  className='product-img'  src={P9} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e6',3)} > <img zoomScale=".5"  className='product-img'  src={P10} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e7',2)} > <img zoomScale=".5"  className='product-img'  src={P11} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e8',3)} > <img zoomScale=".5"  className='product-img'  src={P12} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('e9',3)} > <img zoomScale=".5"  className='product-img'  src={P13} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('p1',2)} > <img zoomScale=".5"  className='product-img'  src={P14} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('p2',4)} > <img zoomScale=".5"  className='product-img'  src={P15} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('p3',3)} > <img zoomScale=".5"  className='product-img'  src={P16} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('p4',2)} > <img zoomScale=".5"  className='product-img'  src={P17} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('p5',3)} > <img zoomScale=".5"  className='product-img'  src={P18} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('p6',3)} > <img zoomScale=".5"  className='product-img'  src={P19} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('p7',2)} > <img zoomScale=".5"  className='product-img'  src={P20} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r10',2)} > <img zoomScale=".5"  className='product-img'  src={P21} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r11',2)} > <img zoomScale=".5"  className='product-img'  src={P22} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r12',3)} > <img zoomScale=".5"  className='product-img'  src={P23} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r13',3)} > <img zoomScale=".5"  className='product-img'  src={P24} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r14',2)} > <img zoomScale=".5"  className='product-img'  src={P25} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r15',2)} > <img zoomScale=".5"  className='product-img'  src={P26} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r16',4)} > <img zoomScale=".5"  className='product-img'  src={P27} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r17',3)} > <img zoomScale=".5"  className='product-img'  src={P28} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r18',2)} > <img zoomScale=".5"  className='product-img'  src={P29} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r1',3)} > <img zoomScale=".5"  className='product-img'  src={P30} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r2',5)} > <img zoomScale=".5"  className='product-img'  src={P31} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r3',1)} > <img zoomScale=".5"  className='product-img'  src={P32} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r4',2)} > <img zoomScale=".5"  className='product-img'  src={P33} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r5',2)} > <img zoomScale=".5"  className='product-img'  src={P34} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r6',2)} > <img zoomScale=".5"  className='product-img'  src={P35} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r7',4)} > <img zoomScale=".5"  className='product-img'  src={P36} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r8',7)} > <img zoomScale=".5"  className='product-img'  src={P37} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z  centerDiv' onClick={() => handleShow('r9',3)} > <img zoomScale=".5"  className='product-img'  src={P38} alt='yuvaish-jewels-ring-1' /></div>

         </div>

       </div>
 
     </div>
    
   </section>

 );

}

export default ProductGallery;
