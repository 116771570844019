import useEffect from 'react' 
import './style.css';
import Home from './home';
import ProductGallery from './productGallery';
import About from "./about"
import Product from './product';
import Testmonial from './testimonials';
import Faqs from './faqs';
import React, { Fragment, Component } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import {   useLocation } from "react-router-dom";
import SRing from "./Gallery/Sliver/Ring";

import SPendant from "./Gallery/Sliver/Pendant";
// import SEarRing from "./Gallery/Sliver/EarRing";
// import GRing from "./Gallery/Gold/Ring";
// import GEarRing from "./Gallery/Gold/EarRing";
// import GPendant from "./Gallery/Gold/Pendant";
 import ContactUs from './contactUs';
 
 import { ToastContainer } from "react-toastify";
function App() {

  return (
    <Fragment>
 
    <Router onUpdate={() => window.scrollTo(0, 0)}  >
 
 
   
      <Routes>
        <Route  path="/"  exact     element={<Home/>} />
        <Route  path="/about"   element={<About/>} />
        <Route  path="/product"   element={<Product/>} />
        <Route  path="/testimonials"   element={<Testmonial/>} />
        <Route  path="/faqs"   element={<Faqs/>} />
        <Route  path="/ContactUs"   element={<ContactUs/>} />
        <Route  path="/ProductGallery"   element={<ProductGallery/>} />
        <Route  path="/ProductGallery"   element={<ProductGallery/>} />
        <Route  path="/sRing"   element={<SRing/>} />
        {/* <Route  path="/sEarRing"   element={<SEarRing />} /> */}
        <Route  path="/sPendant"   element={<SPendant />} />
        {/* <Route  path="/gRing"   element={<GRing />} />
        <Route  path="/gEarRing"   element={<GEarRing />} />
        <Route  path="/gPendant"   element={<GPendant />} /> */}
      </Routes>
   
    </Router>
  </Fragment>
  );
}

export default App;
