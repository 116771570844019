import "../../style.css";
import AboutImg from "../../img/about/yuvaish-jewels-about-img.png";
import ScrollToTop from "../../ScrollToTop";
function About() {
  return (
    <section id={ "About"+  Math.floor(Math.random() * (100))} className="section-about padit">
      <div className="container">
        <div className="about-content">
          <img
            src={AboutImg}
            alt="yuvaish-jewels-about-img"
            className="about-img"
          />
          <div>
            <h2 className="h-2">About Us</h2>
            <p className="p-1">
            We are into Jewelry manufacturing business since 1980 with motto of delivering exclusive jewellery at an affordable price. Manufacturing unit in India leverage us lowering labour cost. Yuvaish Jewels in Canada was founded on the belief that “style should not break the bank and it should look premium”. We specialise in gold, diamond, sterling silver (925) with moissanite. Yuvaish Jewels always stand by their products in terms of authenticity and quality. We also offer its clients to customize their jewellery for special occasions.

            </p>
            <div className="sub-about">
              <div>
                <h2 className="h-3">Our Mission</h2>
                <p className="p-1">
                Our Mission is to pamper every person around the globe to feel like a queen and king and shine like a sun.
                </p>
              </div>
              <div>
                <h2 className="h-3">Our Inspiration</h2>
                <p className="p-1">
                  Yuvaish Jewels is inspired by high achievers, innovators and
                  our designs which are styled to make every female confident.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop/>
    </section>
  );
}

export default About;
