 
 
import logo from '../../img/logo/YV-WEB-LOGO.png'
import '../../style.css'
function Hero() {
  return (
    <section className="section-hero">
    <div className="container">
        <div className="f-center fd-c min-h-100vh">
            <h1 className="h-1">Yuvaish Jewels</h1>
            <p className="p-hero">Shine Like Sun</p>
        </div>
    </div>
</section>
 
  );
}

export default Hero;
