import "../../style.css";

import ContactImg from "../../img/contactUs/yuvaish-jewels-contact-us.png";
import Form from "../../_component/form";
function ContactUs() {
  return (
    <section id={ "ContactUs"+  Math.floor(Math.random() * (100))} className="section-contact-us padit">
      <div className="container">
        <h2 className="h-2 text-center">Contact Us</h2>
        <div className="contact-content">
          <img src={ContactImg} alt="" className="contact-img" />
          <div className="contact-form">
          <Form addressInfo={false} />
            {/* <input
              type="text"
              autocomplete="off"
              required
              className="user-in"
              placeholder="Name"
            />
            <input
              type="email"
              autocomplete="off"
              required
              className="user-in"
              placeholder="E-mail"
            />
            <textarea
              name="description"
              id=""
              className="user-in t-area"
              placeholder="Description"
            ></textarea>
            <button className="btn-primary" type="button">
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
