import "../../style.css";
 
import { Link } from "react-router-dom";
 
import React, { useEffect, useState } from "react";
function Product() {
 
 
  
  
      const [show, setShow] = useState(false);
      function handleShow() {
        // setFullscreen(breakpoint);
        setShow(true);
      }
  return (

 


    <section id={ "product"+  Math.floor(Math.random() * (100))} className="section-product padit">

 
        
  
      <div className="container">
        
        <h2 className="h-2 text-center p-small">Our Products</h2>
        <div className="sm-container">
          {/* icon for instagram */}
          <a    className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 333333 333333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm-40642 71361h81288c30526 0 55489 24654 55489 54772v81069c0 30125-24963 54771-55488 54771l-81289-1c-30526 0-55492-24646-55492-54771v-81069c0-30117 24966-54771 55492-54771zm40125 43843c29663 0 53734 24072 53734 53735 0 29667-24071 53735-53734 53735-29672 0-53739-24068-53739-53735 0-29663 24068-53735 53739-53735zm0 18150c19643 0 35586 15939 35586 35585 0 19647-15943 35589-35586 35589-19650 0-35590-15943-35590-35589s15940-35585 35590-35585zm51986-25598c4819 0 8726 3907 8726 8721 0 4819-3907 8726-8726 8726-4815 0-8721-3907-8721-8726 0-4815 3907-8721 8721-8721zm-85468-20825h68009c25537 0 46422 20782 46422 46178v68350c0 25395-20885 46174-46422 46174l-68009 1c-25537 0-46426-20778-46426-46174v-68352c0-25395 20889-46177 46426-46177z" />
            </svg>
          </a>

          {/* icon for tiktok */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3333 3333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm361 744c31 262 177 418 430 434v294c-147 14-276-34-426-124v550c0 700-763 918-1069 417-197-322-76-889 556-911v311c-48 8-99 20-146 36-141 47-220 137-198 294 43 301 595 390 549-198V745h305z" />
            </svg>
          </a>

          {/* icon for facebook */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3333 3333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm186 1117h311V744h-311c-240 0-435 195-435 435v186h-249v373h249v994h373v-994h311l62-373h-373v-186c0-34 28-62 62-62z" />
            </svg>
          </a>
        </div>
        {/* <p className="p-1 text-center f-small">
          We deliver premium on trend jewellery for the minimum price to have
          more impact on your style.
        </p> */}
        <div>
          {/* <h3 className="h-3 text-center">Rings</h3>
          <div className="product-rings">
            <div className="p-r">
              <img
                className="product-img"
                src={Ring1}
                alt="yuvaish-jewels-ring-01"
              />
              <Link to="/sRing" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>

            <div className="p-r">
              <img
                className="product-img"
                src={Ring2}
                alt="yuvaish-jewels-ring-02"
              />
              <Link to="/sRing" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>

            <div className="p-r">
              <img
                className="product-img"
                src={Ring3}
                alt="yuvaish-jewels-ring-03"
              />
                 <Link to="/sRing" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>

            <div className="p-r">
              <img
                className="product-img"
                src={Ring4}
                alt="yuvaish-jewels-ring-03"
              />
              <Link to="/sRing" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>
          </div> */}

          <h3 className="h-3 text-center">Moissanite & Silver</h3>
       <div className="centerDiv"  >   <Link to="/sPendant" className=" btn-moons "
              >Gallery</Link></div>
          {/* <div className="product-rings">
            <div className="p-r">
              <img
                className="product-img"
                src={Product1}
                alt="yuvaish-jewels-ring-01"
              />
              <Link to="/sPendant" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>

            <div className="p-r">
              <img
                className="product-img"
                src={Product2}
                alt="yuvaish-jewels-ring-02"
              />
                <Link to="/sPendant" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>

            <div className="p-r">
              <img
                className="product-img"
                src={Product3}
                alt="yuvaish-jewels-ring-03"
              />
                <Link to="/sPendant" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>

            <div className="p-r">
              <img
                className="product-img"
                src={Product4}
                alt="yuvaish-jewels-ring-03"
              />
          <Link to="/sPendant" className="btn-secondary btn-gallary">
                Gallery
              </Link>
            </div>
          </div> */}

        
        </div>
      </div>
    </section>
  
  );
}

export default Product;
