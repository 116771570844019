 
import 'bootstrap/dist/css/bootstrap.min.css';
 
import React from "react";
// import { Link } from "react-router-dom";
import logo from "../../img/logo/YV-WEB-LOGO.png";
import "./style.css";
// import ScrollToTop from "../../ScrollToTop";
import config from "../../config";
import { Link } from 'react-router-dom';
const footerLinks = [
  {
    title: "Quick Links",
    menus: [
      { name: "Home", route: "#" },
      { name: "About", route: "about" },
      { name: "Products", route: "product" },
    ],
  },
  {
    title: "---------",
    menus: [
      { name: "Testimonials", route: "testimonials" },
      { name: "Faqs", route: "faqs" },
      { name: "Contact Us", route: "ContactUs" },
    ],
  },

  {
    title: "Contact Us",
    menus: [
      // { name: config.Address.caption },
      // { name: config.Address.street },
      // { name: config.Address.city },
      // { name: config.Address.phone },
      { name: config.Address.email },
    ],
  },
];

const Footer = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to ="/">
                  <img src={logo} alt="" />
                </Link>
                <p></p>
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  {/* <ScrollToTop /> */}
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>
                        {item.route ? (
                          <Link to={`/${item.route}`}>{item.name}</Link>
                        ) : (
                          `${item.name}`
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>
                  Privacy Policy | © 2023 Yuvaish Jewels. All rights reserved
                </span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                <ul className="socialListFooter">
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.facebook.com/profile.php?id=100076569611115"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.instagram.com/YuvaishJewelslawfirm/"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target={"_blank"}
                      href="https://www.linkedin.com/company/YuvaishJewels-law-professional-corporation/about/"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
 

export default Footer;
