import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
import "./style.css";
import InnerImageZoom from 'react-inner-image-zoom';
class HeroSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };
 
    return (
      <Slider className={this.props.className} {...settings}>
        {
        
        this.props.arrayimageBig.map((slider, i) => (
          <div  
       
          key={i} className={`slideWrapper ${slider.images+"1"}`}>
            
            <div className="sliderContent">
  
            <InnerImageZoom   zoomPreload="true" fullscreenOnMobile="true" zoomScale="1"   className='imageCenter' zoomSrc= {  this.props.arrayimageBig[i]}  src={this.props.arrayimageSmall[i]} alt='yuvaish-jewels-ring-2' /> 
              <div className="container">
            
                <div className="row">
                  <div className="col col-lg-8">
                    {/* <p>{slider.text}</p>
                    <h2>
                      <span>{slider.title}</span> <span>{slider.subTitle}</span>
                    </h2>
                    <div className="btnStyle btnStyle3">
                    <NavLink  to={slider.link}>{slider.button}</NavLink>  
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}
export default HeroSlider;
