 
import '../style.css';
import Menu from '../_component/menu';
import Hero from '../_component/hero';
import About from '../_page/about';
import Product from '../_page/product';
import Testimonials from '../_page/testimonials';
import Faqs from '../_page/faqs';
import ContactUs from '../_page/contactUs';
import Footer from '../_component/footer';
import DiamondsVsMoissanite from '../_page/DiamondsVsMoissanite';
import Pendant from "../Gallery/Sliver/Pendant";
 
 
function Page() {
  return (
    <>
    <div className="main-body-bg"></div>
    <div className="App">
    <Menu activePage="product"/>
 <main>
  
  <Product/>
  {/* <DiamondsVsMoissanite/>
  <ContactUs/> */}
   <div style={{display:"none"}}>
  <Pendant/>
  </div>
  <Footer/>
 
  </main>
    </div>
 
    </>
  );
}

export default Page;
