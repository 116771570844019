import "../../../../../src/style.css";


// import P1 from '../../../../img/products/Gallery/Sliver/Ring/r-1.jpg';
// import P2 from '../../../../img/products/Gallery/Sliver/Ring/r-2.jpg';
// import P3 from '../../../../img/products/Gallery/Sliver/Ring/r-3.jpg';
// import P4 from '../../../../img/products/Gallery/Sliver/Ring/r-4.jpg';
// import P5 from '../../../../img/products/Gallery/Sliver/Ring/r-5.jpg';
// import P6 from '../../../../img/products/Gallery/Sliver/Ring/r-6.jpg';
// import P7 from '../../../../img/products/Gallery/Sliver/Ring/r-7.jpg';
// import P8 from '../../../../img/products/Gallery/Sliver/Ring/r-8.jpg';
// import P9 from '../../../../img/products/Gallery/Sliver/Ring/r-9.jpg';
// import P10 from '../../../../img/products/Gallery/Sliver/Ring/r-10.jpg';
// import P11 from '../../../../img/products/Gallery/Sliver/Ring/r-11.jpg';
// import P12 from '../../../../img/products/Gallery/Sliver/Ring/r-12.jpg';
// import P13 from '../../../../img/products/Gallery/Sliver/Ring/r-13.jpg';
// import P14 from '../../../../img/products/Gallery/Sliver/Ring/r-14.jpg';
// import P15 from '../../../../img/products/Gallery/Sliver/Ring/r-15.jpg';
// import P16 from '../../../../img/products/Gallery/Sliver/Ring/r-16.jpg';
// import P17 from '../../../../img/products/Gallery/Sliver/Ring/r-17.jpg';
// import P18 from '../../../../img/products/Gallery/Sliver/Ring/r-18.jpg';
// import P19 from '../../../../img/products/Gallery/Sliver/Ring/r-19.jpg';
// import P20 from '../../../../img/products/Gallery/Sliver/Ring/r-20.jpg';
//  import Pendant from "../Pendant/index"

// import { useSearchParams } from 'react-router-dom';
 
// import { Link } from "react-router-dom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
// import InnerImageZoom from 'react-inner-image-zoom';
function ProductGallery( ) {
   const b1="/img/products/Gallery/Sliver/Ring/r-1.jpg"
   const b2="/img/products/Gallery/Sliver/Ring/r-2.jpg"
   const b3="/img/products/Gallery/Sliver/Ring/r-3.jpg"
   const b4="/img/products/Gallery/Sliver/Ring/r-4.jpg"
   const b5="/img/products/Gallery/Sliver/Ring/r-5.jpg"
   const b6="/img/products/Gallery/Sliver/Ring/r-6.jpg"
   const b7="/img/products/Gallery/Sliver/Ring/r-7.jpg"
   const b8="/img/products/Gallery/Sliver/Ring/r-8.jpg"
   const b9="/img/products/Gallery/Sliver/Ring/r-9.jpg"
   const b10="/img/products/Gallery/Sliver/Ring/r-10.jpg"
   const b11="/img/products/Gallery/Sliver/Ring/r-11.jpg"
   const b12="/img/products/Gallery/Sliver/Ring/r-12.jpg"
   const b13="/img/products/Gallery/Sliver/Ring/r-13.jpg"
   const b14="/img/products/Gallery/Sliver/Ring/r-14.jpg"
   const b15="/img/products/Gallery/Sliver/Ring/r-15.jpg"
   const b16="/img/products/Gallery/Sliver/Ring/r-16.jpg"
   const b17="/img/products/Gallery/Sliver/Ring/r-17.jpg"
   const b18="/img/products/Gallery/Sliver/Ring/r-18.jpg"
   const b19="/img/products/Gallery/Sliver/Ring/r-19.jpg"
   const b20="/img/products/Gallery/Sliver/Ring/r-20.jpg"
 
   return (

 
  
    
 
  <>
          {/* <div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b1} src={P1} alt='yuvaish-jewels-ring-1' /></div>
<div className='p-z'> <InnerImageZoom   zoomScale=".5"  className='product-img' zoomSrc= {b2}  src={P2} alt='yuvaish-jewels-ring-2' />   </div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b3} src={P3} alt='yuvaish-jewels-ring-3' />   </div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b4} src={P4} alt='yuvaish-jewels-ring-4' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b5} src={P5} alt='yuvaish-jewels-ring-5' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b6} src={P6} alt='yuvaish-jewels-ring-6' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b7} src={P7} alt='yuvaish-jewels-ring-7' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b8} src={P8} alt='yuvaish-jewels-ring-8' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b9} src={P9} alt='yuvaish-jewels-ring-9' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b10} src={P10} alt='yuvaish-jewels-ring-10' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b11} src={P11} alt='yuvaish-jewels-ring-11' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b12} src={P12} alt='yuvaish-jewels-ring-12' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b13} src={P13} alt='yuvaish-jewels-ring-13' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b14} src={P14} alt='yuvaish-jewels-ring-14' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b15} src={P15} alt='yuvaish-jewels-ring-15' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b16} src={P16} alt='yuvaish-jewels-ring-16' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b17} src={P17} alt='yuvaish-jewels-ring-17' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b18} src={P18} alt='yuvaish-jewels-ring-18' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b19} src={P19} alt='yuvaish-jewels-ring-19' /></div>
<div className='p-z'> <InnerImageZoom zoomScale=".5"  className='product-img' zoomSrc={b20} src={P20} alt='yuvaish-jewels-ring-20' /></div>
    */}
</>
   
 
  );
 
}

export default ProductGallery;
