import "../../style.css";
 
// import Ring1 from '../../img/products/r-gallary/r-1.jpg';
// import Ring2 from '../../img/products/r-gallary/r-2.jpg';
// import Ring3 from '../../img/products/r-gallary/r-3.jpg';
// import Ring4 from '../../img/products/r-gallary/r-4.jpg';
// import Ring5 from '../../img/products/r-gallary/r-5.jpg';
// import Ring6 from '../../img/products/r-gallary/r-6.jpg';
// import Ring7 from '../../img/products/r-gallary/r-7.jpg';
// import Ring8 from '../../img/products/r-gallary/r-8.jpg';
// import Ring9 from '../../img/products/r-gallary/r-9.jpg';
// import Ring10 from '../../img/products/r-gallary/r-10.jpg';
// import Ring11 from '../../img/products/r-gallary/r-11.jpg';
// import Ring12 from '../../img/products/r-gallary/r-12.jpg';
// import Ring13 from '../../img/products/r-gallary/r-13.jpg';
// import Ring14 from '../../img/products/r-gallary/r-14.jpg';
// import Ring15 from '../../img/products/r-gallary/r-15.jpg';
// import Ring16 from '../../img/products/r-gallary/r-16.jpg';
// import Ring17 from '../../img/products/r-gallary/r-17.jpg';
// import Ring18 from '../../img/products/r-gallary/r-18.jpg';
// import Ring19 from '../../img/products/r-gallary/r-19.jpg';
// import Ring20 from '../../img/products/r-gallary/r-20.jpg';
// import Moons1 from '../../img/products/m-gallary/m-1.jpg';
// import Moons2 from '../../img/products/m-gallary/m-2.jpg';
// import Moons3 from '../../img/products/m-gallary/m-3.jpg';
// import Moons4 from '../../img/products/m-gallary/m-4.jpg';
// import Moons5 from '../../img/products/m-gallary/m-5.jpg';
// import Moons6 from '../../img/products/m-gallary/m-6.jpg';
// import Moons7 from '../../img/products/m-gallary/m-7.jpg';
// import Moons8 from '../../img/products/m-gallary/m-8.jpg';
// import Moons9 from '../../img/products/m-gallary/m-9.jpg';
// import Moons10 from '../../img/products/m-gallary/m-10.jpg';
// import Moons11 from '../../img/products/m-gallary/m-11.jpg';
// import Moons12 from '../../img/products/m-gallary/m-12.jpg';
// import Moons13 from '../../img/products/m-gallary/m-13.jpg';
// import Moons14 from '../../img/products/m-gallary/m-14.jpg';
// import Moons15 from '../../img/products/m-gallary/m-15.jpg';
// import Moons16 from '../../img/products/m-gallary/m-16.jpg';
// import Moons17 from '../../img/products/m-gallary/m-17.jpg';
// import Moons18 from '../../img/products/m-gallary/m-18.jpg';
// import Moons19 from '../../img/products/m-gallary/m-19.jpg';
// import Moons20 from '../../img/products/m-gallary/m-20.jpg';
// import Moons21 from '../../img/products/m-gallary/m-21.jpg';
// import Moons22 from '../../img/products/m-gallary/m-22.jpg';
// import Moons23 from '../../img/products/m-gallary/m-23.jpg';
// import Moons24 from '../../img/products/m-gallary/m-24.jpg';
// import Moons25 from '../../img/products/m-gallary/m-25.jpg';
// import Moons26 from '../../img/products/m-gallary/m-26.jpg';
// import Moons27 from '../../img/products/m-gallary/m-27.jpg';
// import Moons28 from '../../img/products/m-gallary/m-28.jpg';
// import Moons29 from '../../img/products/m-gallary/m-29.jpg';

import { useSearchParams } from 'react-router-dom';
 
import { Link } from "react-router-dom";
function ProductGallery(props) {
  const [searchParams] = useSearchParams();
  const ProductType=searchParams.get('ProductType')
  if(ProductType !== undefined && ProductType !== null)
  {
    ProductType=props.ProductType
  }
 if(ProductType==="mspRing")
   return (

 
  <section id="product" className="section-product padit">
      <div className="container">
        <h2 className="h-2 text-center p-small">Our Products Gallery</h2>
        <div className="sm-container">
          {/* icon for instagram */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 333333 333333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm-40642 71361h81288c30526 0 55489 24654 55489 54772v81069c0 30125-24963 54771-55488 54771l-81289-1c-30526 0-55492-24646-55492-54771v-81069c0-30117 24966-54771 55492-54771zm40125 43843c29663 0 53734 24072 53734 53735 0 29667-24071 53735-53734 53735-29672 0-53739-24068-53739-53735 0-29663 24068-53735 53739-53735zm0 18150c19643 0 35586 15939 35586 35585 0 19647-15943 35589-35586 35589-19650 0-35590-15943-35590-35589s15940-35585 35590-35585zm51986-25598c4819 0 8726 3907 8726 8721 0 4819-3907 8726-8726 8726-4815 0-8721-3907-8721-8726 0-4815 3907-8721 8721-8721zm-85468-20825h68009c25537 0 46422 20782 46422 46178v68350c0 25395-20885 46174-46422 46174l-68009 1c-25537 0-46426-20778-46426-46174v-68352c0-25395 20889-46177 46426-46177z" />
            </svg>
          </a>

          {/* icon for tiktok */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3333 3333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm361 744c31 262 177 418 430 434v294c-147 14-276-34-426-124v550c0 700-763 918-1069 417-197-322-76-889 556-911v311c-48 8-99 20-146 36-141 47-220 137-198 294 43 301 595 390 549-198V745h305z" />
            </svg>
          </a>

          {/* icon for facebook */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3333 3333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm186 1117h311V744h-311c-240 0-435 195-435 435v186h-249v373h249v994h373v-994h311l62-373h-373v-186c0-34 28-62 62-62z" />
            </svg>
          </a>
        </div>
        {/* <p className="p-1 text-center f-small">
          We deliver premium on trend jewellery for the minimum price to have
          more impact on your style.
        </p> */}
        <div>
          <h3 className="h-3 text-center">Rings</h3>
          <div className="product-rings">
          
          {/* <div className='p-r'> <img className='product-img'  src={Ring1} alt='yuvaish-jewels-ring-1' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring2} alt='yuvaish-jewels-ring-2' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring3} alt='yuvaish-jewels-ring-3' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring4} alt='yuvaish-jewels-ring-4' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring5} alt='yuvaish-jewels-ring-5' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring6} alt='yuvaish-jewels-ring-6' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring7} alt='yuvaish-jewels-ring-7' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring8} alt='yuvaish-jewels-ring-8' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring9} alt='yuvaish-jewels-ring-9' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring10} alt='yuvaish-jewels-ring-10' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring11} alt='yuvaish-jewels-ring-11' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring12} alt='yuvaish-jewels-ring-12' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring13} alt='yuvaish-jewels-ring-13' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring14} alt='yuvaish-jewels-ring-14' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring15} alt='yuvaish-jewels-ring-15' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring16} alt='yuvaish-jewels-ring-16' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring17} alt='yuvaish-jewels-ring-17' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring18} alt='yuvaish-jewels-ring-18' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring19} alt='yuvaish-jewels-ring-19' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Ring20} alt='yuvaish-jewels-ring-20' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
          */}
 </div> 
        </div>
      </div>
    </section>
 
 

    

  );
 else
 return (

 
  <section id="product" className="section-product padit">
      <div className="container">
        <h2 className="h-2 text-center p-small">Our Products Gallery</h2>
        <div className="sm-container">
          {/* icon for instagram */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 333333 333333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M166667 0c92048 0 166667 74619 166667 166667s-74619 166667-166667 166667S0 258715 0 166667 74619 0 166667 0zm-40642 71361h81288c30526 0 55489 24654 55489 54772v81069c0 30125-24963 54771-55488 54771l-81289-1c-30526 0-55492-24646-55492-54771v-81069c0-30117 24966-54771 55492-54771zm40125 43843c29663 0 53734 24072 53734 53735 0 29667-24071 53735-53734 53735-29672 0-53739-24068-53739-53735 0-29663 24068-53735 53739-53735zm0 18150c19643 0 35586 15939 35586 35585 0 19647-15943 35589-35586 35589-19650 0-35590-15943-35590-35589s15940-35585 35590-35585zm51986-25598c4819 0 8726 3907 8726 8721 0 4819-3907 8726-8726 8726-4815 0-8721-3907-8721-8726 0-4815 3907-8721 8721-8721zm-85468-20825h68009c25537 0 46422 20782 46422 46178v68350c0 25395-20885 46174-46422 46174l-68009 1c-25537 0-46426-20778-46426-46174v-68352c0-25395 20889-46177 46426-46177z" />
            </svg>
          </a>

          {/* icon for tiktok */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3333 3333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm361 744c31 262 177 418 430 434v294c-147 14-276-34-426-124v550c0 700-763 918-1069 417-197-322-76-889 556-911v311c-48 8-99 20-146 36-141 47-220 137-198 294 43 301 595 390 549-198V745h305z" />
            </svg>
          </a>

          {/* icon for facebook */}
          <a href="#" className="sm-link">
            <svg
              className="sm-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3333 3333"
              shapeRendering="geometricPrecision"
               textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm186 1117h311V744h-311c-240 0-435 195-435 435v186h-249v373h249v994h373v-994h311l62-373h-373v-186c0-34 28-62 62-62z" />
            </svg>
          </a>
        </div>
        {/* <p className="p-1 text-center f-small">
          We deliver premium on trend jewellery for the minimum price to have
          more impact on your style.
        </p> */}
        <div>
  <h3 className="h-3 text-center">Moissanite & Silver</h3>
          <div className="product-rings">
          {/* <div className='p-r'> <img className='product-img'  src={Moons1} alt='yuvaish-jewels-Moissanite-1' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons2} alt='yuvaish-jewels-Moissanite-2' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons3} alt='yuvaish-jewels-Moissanite-3' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons4} alt='yuvaish-jewels-Moissanite-4' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons5} alt='yuvaish-jewels-Moissanite-5' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons6} alt='yuvaish-jewels-Moissanite-6' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons7} alt='yuvaish-jewels-Moissanite-7' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons8} alt='yuvaish-jewels-Moissanite-8' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons9} alt='yuvaish-jewels-Moissanite-9' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons10} alt='yuvaish-jewels-Moissanite-10' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons11} alt='yuvaish-jewels-Moissanite-11' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons12} alt='yuvaish-jewels-Moissanite-12' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons13} alt='yuvaish-jewels-Moissanite-13' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons14} alt='yuvaish-jewels-Moissanite-14' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons15} alt='yuvaish-jewels-Moissanite-15' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons16} alt='yuvaish-jewels-Moissanite-16' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons17} alt='yuvaish-jewels-Moissanite-17' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons18} alt='yuvaish-jewels-Moissanite-18' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons19} alt='yuvaish-jewels-Moissanite-19' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons20} alt='yuvaish-jewels-Moissanite-20' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons21} alt='yuvaish-jewels-Moissanite-21' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons22} alt='yuvaish-jewels-Moissanite-22' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons23} alt='yuvaish-jewels-Moissanite-23' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons24} alt='yuvaish-jewels-Moissanite-24' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons25} alt='yuvaish-jewels-Moissanite-25' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons26} alt='yuvaish-jewels-Moissanite-26' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons27} alt='yuvaish-jewels-Moissanite-27' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons28} alt='yuvaish-jewels-Moissanite-28' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div>
<div className='p-r'> <img className='product-img'  src={Moons29} alt='yuvaish-jewels-Moissanite-29' /> <Link to='/ContactUs' className='btn-secondary btn-gallary'> Contact Us </Link> </div> */}

          </div>

          
        </div>
      </div>
    </section>
 
 

    

  );  
}

export default ProductGallery;
