 
import './style.css';
import Menu from './_component/menu';
import Hero from './_component/hero';
import About from './_page/about';
 
import Footer from './_component/footer';
 import ScrollToTop from './ScrollToTop';
 import DiamondsVsMoissanite from './_page/DiamondsVsMoissanite';

function Home() {
  return (
    <>
    <div className="main-body-bg"></div>
    <div className="App">
 <Menu activePage={"/"}/>
 <main>
  <Hero/>
  <About/>
  {/* <Product/>
  <DiamondsVsMoissanite/>
  <Testimonials className={"padit"}/>
  <Faqs/>
  <ContactUs/> */}

  <Footer/>
  <ScrollToTop />
  </main>
    </div>
    </>
  );
}

export default Home;
